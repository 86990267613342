const Faq = () => {
    return (
        <>
            <h1 className="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h1>
            <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-md">
                <h2 className="text-2xl font-semibold mt-4">How to Request Graphics?</h2>
                <p className="mt-2">You can easily request graphics by following these steps:</p>
                <ol className="list-decimal list-inside mt-2 ml-4">
                    <li>Access the <strong>Request Graphics</strong> section in your dashboard.</li>
                    <li>Fill out the necessary details for your graphic request.</li>
                    <li>Submit your request, and you'll receive a confirmation message.</li>
                </ol>

                <h2 className="text-2xl font-semibold mt-4">How Will I Receive Graphics?</h2>
                <p className="mt-2">
                    Once your graphic request is processed, you will primarily receive updates via:
                </p>
                <ul className="list-disc list-inside mt-2 ml-4">
                    <li>
                        <strong>Telegram:</strong> You will receive your graphics through Telegram for immediate access.
                    </li>
                    <li>
                        <strong>Email:</strong> If you don’t have Telegram, your graphics will be sent to your email. Ensure your email is up to date in your profile settings.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-4">Need Further Assistance?</h2>
                <p className="mt-2">If you have any more questions or need help, feel free to reach out to our support team!</p>
            </div>
        </>
    );
};

export default Faq;
