import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Modal, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axios from '../../util/axiosConfig'; // Assuming you've set up an axios instance
import moment from 'moment';

const Ticket = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Table columns configuration
  const columns = [
    {
      title: 'Id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => {
        let color = status === 'Resolved' ? 'green' : 'orange';
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).fromNow(),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <div>
          <a className="text-blue-600 mr-3" onClick={() => handleView(record)}>View</a>
          <Popconfirm
            title="Are you sure you want to delete this ticket?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <a className="text-rose-600">Delete</a>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // Fetch tickets on component mount
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get('/ticket/my-tickets');
        setTickets(response.data);
      } catch (error) {
        message.error('Error fetching tickets');
        console.error('Error fetching tickets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, []);

  // Handle view ticket details
  const handleView = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalVisible(true);
  };

  // Handle delete ticket with confirmation
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/ticket/ticket/${id}`);
      message.success('Ticket deleted successfully');
      // Remove the deleted ticket from the table data
      setTickets(tickets.filter(ticket => ticket._id !== id));
    } catch (error) {
      message.error('Failed to delete ticket');
      console.error('Error deleting ticket:', error);
    } finally {
      setLoading(false);
    }
  };

  // Close the modal
  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedTicket(null);
  };

  return (
    <>
      <div className="w-full mb-5 flex justify-start">
        <Link to="/request-graphics/open-ticket">
          <Button type="primary" icon={<PlusOutlined />} className="mr-auto">
            New Ticket
          </Button>
        </Link>
      </div>

      <Table
        loading={loading}
        pagination={{ pageSize: 6 }}
        columns={columns}
        dataSource={tickets}
        scroll={{
          x: 'max-content',
        }}
        rowKey="_id" // Ensure proper key for the table rows
      />

      {/* Modal for viewing ticket details */}
      <Modal
        title="Ticket Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedTicket && (
          <div>
            <p><strong>ID:</strong> {selectedTicket._id}</p>
            <p><strong>Status:</strong> {selectedTicket.status}</p>
            <p><strong>Category:</strong> {selectedTicket.category}</p>
            <p><strong>Message:</strong> {selectedTicket.message}</p>
            <p><strong>Telegram:</strong> <a href={selectedTicket.telegram} target="_blank" rel="noopener noreferrer">{selectedTicket.telegram}</a></p>
            <p><strong>Date Created:</strong> {moment(selectedTicket.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Ticket;
