import React, { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message, Alert, Modal } from 'antd';
import axiosInstance from '../../util/axiosConfig';
import { useNavigate , useLocation } from 'react-router-dom';
import logo from "../../assets/logo.png";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal state
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const id = queryParams.get('payment_id');
  
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/auth/login', values);
      const { token } = response.data;

      // Save token in localStorage (or cookies)
      localStorage.setItem('authToken', token);

      message.success('Login successful');
      // Redirect to dashboard
      navigate('/dashboard');
    } catch (error) {
      message.error(error.response?.data?.msg || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  // Function to show the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle modal close
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='w-screen flex-col h-screen flex justify-center items-center bg-[#EEF7FF]'>
      {
        id && (
          <Alert
          message="Payment Success"
          description={
            <>
              We will send your login ID and password to your email. Please ensure the email you submitted is correct. If not, feel free to contact us via  
               <a href="https://www.instagram.com/designerbayofficial?igsh=aGZvM3cweXZud256" className='ml-1' target="_blank" rel="noopener noreferrer" style={{ color: '#1890ff' }}>
                 Instagram
              </a>
              , and we'll assist you there. Thank you!
            </>
          }
          type="info"
          showIcon
          className='my-10'
        />
        
        )
      }
      
      <Form
        name="login"
        initialValues={{ remember: true }}
        style={{ maxWidth: 360 }}
        onFinish={onFinish}
        className='w-[25rem] p-9 border-2 rounded-lg bg-white'
      >
        <div className='flex justify-center my-10'>
          <img src={logo} width={150} alt="Logo" />
        </div>

        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <a href="">Forgot password</a>
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={loading}>
            Log in
          </Button>
        </Form.Item>

        {/* Trigger the modal when clicking "New user?" */}
        <p className='text-center text-blue-900 cursor-pointer' onClick={showModal}>Register</p>

        {/* Modal Component */}
        <Modal
          title="Registration Notice"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="OK"
        >
          <p>You cannot register directly. You need to purchase a premium membership to use this site. Please contact support for further details.</p>
        </Modal>
      </Form>
    </div>
  );
};

export default Login;
