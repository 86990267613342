import React, { useState, useEffect } from 'react';
import { Input, Button, message, Spin, Alert } from 'antd';

import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import axios from '../../util/axiosConfig';
const FreePick = () => {
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState('image'); // Default to 'image'
  const [downloadLink, setDownloadLink] = useState('');

  const handleDownload = async () => {
    if (!fileUrl) {
      message.error('Please enter a file URL');
      return;
    }

    setLoading(true); // Start loading

    try {
      
      // Perform the POST request with headers containing the authorization token
      const response = await axios.post(`/download/image` , { fileUrl: fileUrl }) 
        
      setDownloadLink(response.data.downloadLink);

      if (response.data.msg === 'Trail User can only download one file') {
        message.error(response.data.msg);
      }
      
    } catch (error) {
      message.error(error.response?.data?.msg || 'Download failed');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Automatically trigger download when downloadLink is set
  useEffect(() => {
    if (downloadLink) {
      const link = document.createElement('a');
      link.href = downloadLink;
      link.setAttribute('download', 'file'); // Optionally specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }, [downloadLink]);

  return (
    <>
      <div className="w-full mb-5 flex justify-start items-center">
        <Link to="/request-graphics/open-ticket">
          <Button type="primary" icon={<PlusOutlined />} className="mr-auto">
            New Ticket
          </Button>
        </Link>
        <p className='text-base font-medium ml-5'>If instant download doesn't work, create a ticket</p>
      </div>
      {/* <p>unser service</p> */}
      <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
        <h1 className='mb-5 text-2xl font-medium'>FreePick</h1>
        <h2 className='mb-3'>Download Your File</h2>

        <Input
          placeholder="Paste the file URL here"
          value={fileUrl}
          onChange={(e) => setFileUrl(e.target.value)}
          style={{ marginBottom: '20px' }}
        />

        <Button
          type="primary"
          onClick={handleDownload}
          disabled={loading || !fileUrl} // Disable button when loading or if no URL is provided
          block
        >
          {loading ? <Spin /> : 'Download'}
        </Button>
      </div>

      {
        downloadLink ? (
          <a className='mb-3 font-medium bg-[#ff0000] p-2 text-white' href={downloadLink} target="_blank" rel="noopener noreferrer">
            Click Here To Get Download Started
          </a>
        ) : ''
      }

      <Alert
        message="DO NOT REFRESH"
        description={<p>Avg wait time: 20 seconds - 1 minute</p>}
        type="error"
        showIcon
        className='my-10'
      />
    </>
  );
};

export default FreePick;
