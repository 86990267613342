import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "./components/pages/Login";
import Main from "./components/Ui/Layout";
import Profile from "./components/pages/Profile";
import Dashbord from "./components/pages/Dashbord";
import Faq from "./components/pages/Faq";
import ProfileView from "./components/pages/Sub-page/Profile-view";
import ChangePassword from "./components/pages/Sub-page/ChangePassword";
import DeleteAccount from "./components/pages/Sub-page/DeleteAccount";
import Ticket from "./components/pages/Ticket";
import OpenTicket from "./components/pages/Sub-page/Open-Ticket";
import AuthGuard from "./components/AuthGuard";
import { UserProvider } from "./components/UserContext"; // Adjust the import path as necessary
import FreePick from "./components/pages/freepik";
import Envato from "./components/pages/envato";
import Flaticon from "./components/pages/flaticon";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <Dashbord />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
  },
  {
    path: "/profile",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <Profile />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
    children: [
      {
        path: "",
        element: <ProfileView />,  // Default when accessing /profile
      },
      {
        path: "change-password",
        element: <ChangePassword />,  // When accessing /profile/change-password
      },
      {
        path: "delete-account",
        element: <DeleteAccount />,  // When accessing /profile/delete-account
      },
    ],
  },
  {
    path: "/request-graphics",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <Ticket />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
  },
  {
    path: "/request-graphics/open-ticket",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <OpenTicket />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
  },
  {
    path: "/freepick-downloader",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <FreePick />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
  },
  {
    path: "/envato-downloader",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <Envato />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
  },
  {
    path: "/flaticon-downloader",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <Flaticon />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
  },
  {
    path: "/faq",
    element: (
      <UserProvider>
        <AuthGuard>
          <Main>
            <Faq />
          </Main>
        </AuthGuard>
      </UserProvider>
    ),
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
]);

export default router;
