import { Link } from "react-router-dom";

const Modal = (props) => {
  return (
    <>
      <div className="rounded-xl flex flex-col max-w-60 items-center p-3 md:p-6 gap-y-6 border-2">
        <img src={props.img} width={200} className="" alt={props.name} />
        
        {/* Conditional rendering for button */}
        {props.name === "FreePik" ? (
          <Link to={`/freepick-downloader`}>
            <button className="px-5 md:px-14 py-2 bg-[#4096ff] font-medium text-white rounded-lg">
              Instant Download
            </button>
          </Link>
        ) : props.name === "Envato" ? (
          <Link to={`/envato-downloader`}>
            <button className="px-5 md:px-14 py-2 bg-[#4096ff] font-medium text-white rounded-lg">
              Instant Download
            </button>
          </Link>
        ) : (
          <Link to={`/flaticon-downloader`}>
            <button className="px-5 md:px-14 py-2 bg-[#4096ff] font-medium text-white rounded-lg">
              Access Now
            </button>
          </Link>
        )}
      </div>
    </>
  );
};

export default Modal;
