import React, { useEffect, useState } from 'react';
import { Input, Button, message, Spin, Select, Alert } from 'antd';
import axios from '../../util/axiosConfig';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

const Envato = () => {
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState('image'); // Default to 'image'
const [downloadLink , setDownloadLink] = useState('')
  const handleDownload = async () => {
    if (!fileUrl) {
      message.error('Please enter a file URL');
      return;
    }

    setLoading(true); // Start loading

    try {
      // Vector should also use the image API
     
      // Perform the POST request with headers containing the authorization token
      const response = await axios.post(`/download/envanto` ,{ fileUrl: fileUrl }) 
       
      setDownloadLink(response.data.downloadLink)
      // const downloadUrl = ; // Assuming the server sends the download URL as 'downloadLink'

      // if (!downloadUrl) {
      //   message.error('Invalid response from server');
      //   return;
      // }

      // // Open the download link in a new tab
      // const link = document.createElement('a');
      // link.href = downloadUrl;
      // link.target = '_blank'; // Open in new tab
      // link.rel = 'noopener noreferrer'; // Security feature
      // link.click(); // Trigger the download

      // message.success('Opening file in a new tab');\

      if(response.data.msg === 'Trail User can only download one file'){

        message.error(response.data.msg);
     
      }
      
    } catch (error) {
      
      message.error(error.response.data.msg);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    if (downloadLink) {
      const link = document.createElement('a');
      link.href = downloadLink;
      link.setAttribute('download', 'file'); // Optionally specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }, [downloadLink]);
  return (
    <>
          <div className="w-full mb-5 flex justify-start">
        <Link to="/request-graphics/open-ticket">
          <Button type="primary" icon={<PlusOutlined />} className="mr-auto">
            New Ticket
          </Button>
        </Link>
        <p className='text-base font-medium ml-5'>If instant download doesn't work create ticket</p>
      </div>
      {/* <p>Under Mainatence</p> */}
    <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
      <h1 className='mb-5 text-2xl font-medium'>Envato</h1>
      <h2 className='mb-3'>Download Your File</h2>
      
    

      <Input
        placeholder="Paste the file URL here"
        value={fileUrl}
        onChange={(e) => setFileUrl(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      <Button
        type="primary"
        onClick={handleDownload}
        disabled={loading || !fileUrl} // Disable button when loading or if no URL is provided
        block
      >
        {loading ? <Spin /> : 'Download'}
      </Button>

      
    </div>
    {
        downloadLink ? (
          <a className='mb-3 font-medium bg-[#ff0000] p-2 text-white' href={downloadLink} target="_blank" rel="noopener noreferrer">
            Click Here To Get Download Started
          </a>
        ) : ''
      }
    <Alert
          message="DO NOT REFRESH"
          description={
            <>
              <p>avg wait time: 20 seconds-1 minute</p>
            </>
          }
          type="error"
          showIcon
          className='my-10'
        />

    </>
    
  );
};

export default Envato;
