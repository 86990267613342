import React from 'react';
import { Button, Form, Input } from 'antd';
import { useUser } from '../../UserContext';
import axios from '../../../util/axiosConfig'; // Adjust the path to your Axios instance
import { message } from 'antd';
const ProfileView = () => {
  const { user , setUpdate} = useUser();

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    
    try {
      // Make the request to update the user profile
      const response = await axios.put('/auth/update-profile', values); // Adjust the endpoint as necessary
      message.success('Profile Updated');
      setUpdate((e)=>!e)
      // Optionally, you can show a success message or update the local user state
    } catch (error) {
      console.error('Failed to update user:', error.response ? error.response.data.message : error.message);
      // Optionally, show an error message to the user
    }
  };

  return (
    <>
      <div className='flex flex-col items-start w-[300px] gap-y-10'>
        <p className='text-2xl font-bold'>Profile</p>
        <Form
          name="profile"
          initialValues={{
            username: user?.user?.username,
            email: user?.user?.email,
            remember: true,
          }}
          style={{ maxWidth: 360 }}
          onFinish={onFinish}
          layout="vertical"
          className='w-full'
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input size="large" placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please input your Email!',
              },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ProfileView;
