import React, { useState } from 'react';
import { Button, Form, Input, Select, Radio, message } from 'antd';
import axios from '../../../util/axiosConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../UserContext';

const { TextArea } = Input;
const { Option } = Select;

const OpenTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const [preferredContact, setPreferredContact] = useState('telegram'); // Default to 'telegram'

  // Function to get query parameters from the URL
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const category = queryParams.get('c'); // Get the 'c' query parameter

  console.log('Selected category:', category); // For debugging

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);

    // Prepare the payload based on the preferred contact method
    const payload = {
      username: values.username,
      email: values.email, // The user's email (will always be included)
      category: values.category,
      description: values.description,
      preferredContact: preferredContact,
      isEmail: preferredContact === 'email' ? true : false, // Boolean to indicate contact method
      telegram: preferredContact === 'telegram' ? values.telegram : undefined, // Include telegram ID if selected
    };

    try {
      const response = await axios.post('/ticket/ticket', payload); // Adjust the URL as needed
      message.success('Ticket created successfully!');
      navigate('/request-graphics');
    } catch (error) {
      message.error(error.response.data.msg);
      
    }
  };

  return (
    <div className='flex flex-col w-full items-center w-[300px] gap-y-10'>
      <p className='text-2xl font-bold'>Open Ticket</p>
      <Form
        name="open_ticket"
        initialValues={{
          username: `${user?.user?.username}`, // Replace with actual username
          email: `${user?.user?.email}`, // Replace with actual email
          category: category || '', // Set the category if it exists, otherwise empty
          preferredContact: 'telegram', // Ensure preferredContact is set to telegram initially
        }}
        style={{ maxWidth: 460 }}
        onFinish={onFinish}
        layout="vertical"
        className='w-full'
      >
        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input size="large" placeholder="Username" disabled />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input size="large" placeholder="Email" disabled />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please input your description!' }]}
        >
          <TextArea size="large" className='max-h-[80px]' placeholder="Graphics Link..." />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Please select a category!' }]}
        >
          <Select size="large" placeholder="Select a category" defaultValue={category}>
            <Option value="freepik">FreePik</Option>
            <Option value="envato">Envato</Option>
            <Option value="flaticon">Flaticon</Option>
          </Select>
        </Form.Item>

        {/* Radio Group for Preferred Contact Method */}
        <Form.Item
          label="Choose where you want to receive graphics"
          name="preferredContact"
          rules={[{ required: true, message: 'Please select a contact method!' }]}
        >
          <Radio.Group onChange={(e) => setPreferredContact(e.target.value)} value={preferredContact}>
            <Radio value="telegram">Telegram</Radio>
            <Radio value="email">Email</Radio>
          </Radio.Group>
        </Form.Item>

        {/* Conditional Rendering based on preferredContact */}
        {preferredContact === 'telegram' && (
          <Form.Item
            name="telegram"
            label="Telegram username"
            rules={[{ required: true, message: 'Please input your Telegram ID!' }]}
          >
            <Input size="large" placeholder="Telegram username" />
          </Form.Item>
        )}

        {/* No extra input for email since it's already captured */}
        {preferredContact === 'email' && (
          <Form.Item>
            <p>You will receive graphics via your registered email: {user?.user?.email}</p>
          </Form.Item>
        )}

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Submit Ticket
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default OpenTicket;
