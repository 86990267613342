
import freepik from "../../assets/service/freepik.png"
import envanto from "../../assets/service/envanto.png"
import flaticon from "../../assets/service/flaticon.png"
import Modal from "../Ui/Modal"
const Dashbord = ()=>{
    return(
<>
<div className="p-4 flex gap-8 md:flex-row flex-col w-full justify-center items-center">
<Modal
 img={freepik}
 name={"FreePik"}
 />
<Modal
 img={envanto}
 name={"Envato"}
 />
 <Modal
 img={flaticon}
 name={"Flaticon"}
 />
</div>


</>
    )
}

export default Dashbord;