import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from '../util/axiosConfig'; // Adjust the import based on your structure
import {  useNavigate } from "react-router-dom";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [update , setUpdate] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('/auth/user'); // Adjust the endpoint as necessary
        console.log(response.data)
        setUser(response.data);
      } catch (error) {
        navigate("/")
        console.error('Error fetching user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [update]);

  return (
    <UserContext.Provider value={{ user, loading , setUpdate }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
