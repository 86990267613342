import React from 'react';
import { Button, Form, Input } from 'antd';
import axios from '../../../util/axiosConfig'; // Adjust the path to your Axios instance
import { message } from 'antd';
const ChangePassword = () => {
 
  const onFinish = async (values) => {
    console.log('Received values of form: ', values);

    try {
      // Make the request to change the password
      const response = await axios.put('/auth/update-password', values); // Adjust the endpoint as necessary
      message.success('Password Updated');
  
      // Optionally, show a success message to the user
    } catch (error) {
      console.error('Failed to change password:', error.response ? error.response.data.message : error.message);
      // Optionally, show an error message to the user
    }
  };

  return (
    <>
      <div className='flex flex-col items-start w-[300px] gap-y-10'>
        <p className='text-2xl font-bold'>Change Password</p>
        <Form
          name="change-password"
          initialValues={{
            remember: true,
          }}
          style={{
            maxWidth: 360,
          }}
          onFinish={onFinish}
          layout="vertical"
          className='w-full'
        >
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: 'Please input your Current Password!',
              },
            ]}
          >
            <Input.Password size="large" placeholder="Current Password" />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please input your New Password!',
              },
            ]}
          >
            <Input.Password size="large" placeholder="New Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: 'Please confirm your New Password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password size="large" placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
