import React from "react";
import profile from "../../assets/profile.png";
import { UserOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Menu, Grid } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";

const { useBreakpoint } = Grid;

const Profile = () => {
  const screens = useBreakpoint();
  const location = useLocation();

  const page = ["Profile", "Change Password"];
  const routes = ["/profile", "/profile/change-password"]; // Add "Delete Account" route
  const icons = [UserOutlined, EyeOutlined]; // Icons for each menu item

  const items = icons.map((icon, index) => ({
    key: String(index + 1),
    icon: React.createElement(icon),
    label: (
      <Link to={routes[index]}>
        {page[index]}
      </Link>
    ),
  }));

  return (
    <div className="flex flex-col md:flex-row">
      {/* Sidebar Section */}
      <div className={`p-10 ${screens.md ? 'ml-20' : 'mx-auto'}`}>
        <div className="flex justify-center flex-col items-center gap-y-4">
          <img src={profile} width={170} alt="Profile" />
          
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          className="mt-5"
          style={{
            height: "100%",
            border: "none",
          }}
          items={items}
        />
      </div>

      {/* Content Section */}
      <div className="p-10 w-full">
        <Outlet /> {/* Render nested routes here */}
      </div>
    </div>
  );
};

export default Profile;
